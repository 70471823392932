import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const factoryPush = createApi({
  reducerPath: 'factoryPush',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    postFactoryPushFile: builder.mutation({
      query: (params) => ({
        url: 'https://hes.integration.test.gomatimvvnl.in/api/hes/factory-file-import',
        body: params,
        method: 'POST'
      })
    })
  })
});

export const { usePostFactoryPushFileMutation } = factoryPush;
